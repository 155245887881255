import "./communaute.css";
import LeftMenu from "../../Layout/LeftMenu/LeftMenu";
export default function Communaute () {
    return (
        <>
            <LeftMenu></LeftMenu>
            <div className="community-page-content-wrapper">
                <h1> Parcourir la communauté PacePartner </h1>
            </div>
        </>
    )
}